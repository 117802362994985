import { useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { csvFormat } from 'd3-dsv'
import saveAs from 'file-saver'
import { chain, includes, lowerCase, omit, pick } from 'lodash'
import { DateTime } from 'luxon'
import { action, makeObservable, observable, toJS } from 'mobx'
import { observer } from 'mobx-react'
import { Dropdown, Form, Message, Modal } from 'semantic-ui-react'

import { DatePicker } from '@/components/componentLibrary'
import analytics from '@/modules/analytics'
import { apipyRequest, internalApi } from '@/modules/api/request'
import { fileFormats } from '@/modules/api/types'
import { saveFile } from '@/modules/api/util'
import i18n from '@/modules/i18n/i18n'
import layerStore from '@/modules/layers/layerStore'
import { monitorMessage } from '@/modules/monitoring'
import { useCurrentRegion } from '@/modules/urlRouting/hooks'
import appUIStore from '@/stores/appUIStore'
import authStore from '@/stores/authStore'

type Geography = { shapeLayerUUID: string; aggregation: string }

export class DownloadReportsModalStore {
  dateRange: { start: DateTime | null; end: DateTime | null } = {
    start: null,
    end: null,
  }
  ready = false
  loading = false
  error = false

  constructor() {
    makeObservable(this, {
      dateRange: observable,
      ready: observable,
      loading: observable,
      error: observable,
      setLoading: action,
      setError: action,
      initDates: action,
      setDateRange: action,
    })
  }

  setLoading(loading: boolean) {
    this.loading = loading
  }

  setError(error: boolean) {
    this.error = error
  }

  initDates() {
    if (!this.dateRange.start) {
      this.dateRange.start = appUIStore.metro.defaultStartDate
    }
    if (!this.dateRange.end) {
      this.dateRange.end = appUIStore.metro.yesterday
    }
  }

  setDateRange(obj: { start: DateTime; end: DateTime }) {
    this.dateRange = obj
  }

  download(reportType: string, geography: Geography) {
    if (!authStore.hasExport) return
    this.setLoading(true)

    analytics.track(`Download ${reportType}`, {
      category: 'Reports',
    })

    if (reportType === 'vehicles_by_day_by_geography')
      this.downloadVehiclesByDayByGeogReport(geography)
    if (reportType === 'trips_top_od') this.downloadTripsTopOD(geography)
    if (reportType === 'calgary_trips_by_origin_neighborhood')
      this.calgaryTripsByOriginNeighborhood()
    if (reportType === 'calgary_trips_by_destination_neighborhood')
      this.calgaryTripsByDestinationNeighborhood()
    if (reportType === 'calgary_deployments_by_neighborhood')
      this.calgaryDeploymentsByNeighborhood()
    if (reportType === 'calgary_utilization') this.calgaryUtilization()
    if (reportType === 'utilization_by_operator_by_geo')
      this.downloadUtilizationByOperatorByGeo(geography)
    if (reportType === 'baltimore_equity_distribution') this.baltimoreEquityReport()
    if (reportType === 'baltimore_planning_distribution') this.baltimorePlanningReport()
  }

  doRequestAndDownload(
    endpoint: string,
    payload: {},
    title: string,
    dataFormatFunc?: (data: any) => any
  ) {
    apipyRequest(endpoint, payload, {
      reject: true,
      headers: { 'accept-language': 'en' },
    })
      .then(response => {
        if (response.success) {
          let data = response.data || response.shape_data_by_day

          if (dataFormatFunc) {
            data = dataFormatFunc(data)
          }

          const text = response.columns ? csvFormat(data, response.columns) : csvFormat(data)
          const blob = new Blob([text], { type: 'text/csv' })
          saveAs(blob, `${title}.csv`)
          this.setError(false)
          this.setLoading(false)
        } else {
          this.setError(true)
          this.setLoading(false)
        }
      })
      .catch(error => {
        monitorMessage(error)
        this.setError(true)
        this.setLoading(false)
      })
  }

  baltimoreEquityReport() {
    const endpoint = '/vehicles/vehicles_by_hour_by_geog_by_operator'
    const title = `baltimore_equity_report`

    const payload = {
      start_date: this.dateRange.start!.toISODate(),
      end_date: this.dateRange.end!.toISODate(),
      shape_layer_uuid: '6232d6ca-4ea6-425a-931c-c5121725a0f1',
      map_ids_to_names: true,
      aggregation: 'max',
      hour_filter: [5, 9],
    }

    this.doRequestAndDownload(endpoint, payload, title)
  }

  baltimorePlanningReport() {
    const endpoint = '/vehicles/vehicles_by_hour_by_geog_by_operator'
    const title = `baltimore_planning_district_report`

    const payload = {
      start_date: this.dateRange.start!.toISODate(),
      end_date: this.dateRange.end!.toISODate(),
      shape_layer_uuid: '945a7bdc-1390-48f2-a262-674d3491876e',
      map_ids_to_names: true,
      aggregation: 'mean',
      hour_filter: [5, 9],
    }

    this.doRequestAndDownload(endpoint, payload, title)
  }

  downloadVehiclesByDayByGeogReport({ shapeLayerUUID, aggregation }: Geography) {
    const endpoint = '/vehicles/vehicles_by_day_by_geog_by_operator'
    const region = appUIStore.metro.regionId
    const geographyName = layerStore.getLayer(shapeLayerUUID)?.layerName
    const aggregationText = aggregation === 'mean' ? 'average' : aggregation
    const title = i18n.t(
      'downloadReportsModal.vehiclesByDayByGeographyTitle',
      '{{region}}_{{aggregationText}}_vehicles_by_{{geographyName}}',
      { region, aggregationText, geographyName: lowerCase(geographyName) }
    )

    const payload = {
      start_date: this.dateRange.start!.toISODate(),
      end_date: this.dateRange.end!.toISODate(),
      shape_layer_uuid: shapeLayerUUID,
      map_ids_to_names: true,
      aggregation,
    }

    this.doRequestAndDownload(endpoint, payload, title)
  }

  calgaryUtilization() {
    // this creates the utilization report discussed here:
    // https://app.asana.com/0/home/1133241482851823/1201349653817260
    const start_date = this.dateRange.start!.toISODate()
    const end_date = this.dateRange.end!.toISODate()
    this.doRequestAndDownload(
      '/custom_reports/calgary/utilization_summary',
      {
        start_date,
        end_date,
      },
      `calgary_daily_trips_summary-${start_date}-${end_date}`
    )
  }

  calgaryDeploymentsByNeighborhood() {
    // this creates the station areas report discussed in detail here:
    // https://app.asana.com/0/1136288780227701/1201197705653737
    const start_date = this.dateRange.start!.toISODate()
    const end_date = this.dateRange.end!.toISODate()
    this.doRequestAndDownload(
      '/custom_reports/calgary/deployments_by_neighborhood',
      {
        neighborhoods_layer_uuid: '0663bbde-3167-4bcd-b500-b8ade8525f25',
        stations_layer_uuid: '0526498f-8e34-43c4-bd47-3a6e06c2657c',
        start_date,
        end_date,
      },
      `calgary_vehicle_counts_by_neighbourhood-${start_date}-${end_date}`
    )
  }

  calgaryTripsByOriginNeighborhood() {
    // this creates the trips by month report discussed in detail here:
    // https://app.asana.com/0/1136288780227701/1201197705653737
    const start_date = this.dateRange.start!.toISODate()
    const end_date = this.dateRange.end!.toISODate()
    this.doRequestAndDownload(
      '/custom_reports/calgary/trips_by_origin_neighborhood',
      {
        start_date,
        end_date,
        shape_layer_uuid: '0663bbde-3167-4bcd-b500-b8ade8525f25',
      },
      `calgary_trips_by_origin_neighbourhood-${start_date}-${end_date}`
    )
  }

  calgaryTripsByDestinationNeighborhood() {
    // this creates the trips by month report distinguished from above:
    // https://app.asana.com/0/1136288780227701/1201411521764662
    const start_date = this.dateRange.start!.toISODate()
    const end_date = this.dateRange.end!.toISODate()
    this.doRequestAndDownload(
      '/custom_reports/calgary/trips_by_destination_neighborhood',
      {
        start_date,
        end_date,
        shape_layer_uuid: '0663bbde-3167-4bcd-b500-b8ade8525f25',
      },
      `calgary_trips_by_destination_neighbourhood-${start_date}-${end_date}`
    )
  }

  downloadTripsTopOD({ shapeLayerUUID }: Geography) {
    const endpoint = '/trips/top_od_pair'
    const region = appUIStore.metro.regionId
    const geographyName = layerStore.getLayer(shapeLayerUUID)?.layerName
    const title = i18n.t(
      'downloadReportsModal.topTripsOriginDestinationTitle',
      '{{region}}_top_trips_origin_destination_{{geographyName}}',
      { region, geographyName: lowerCase(geographyName) }
    )

    const payload = {
      start_date: this.dateRange.start!.toISODate(),
      end_date: this.dateRange.end!.toISODate(),
      shape_layer_uuid: shapeLayerUUID,
      map_ids_to_names: true,
    }

    this.doRequestAndDownload(endpoint, payload, title)
  }

  downloadUtilizationByOperatorByGeo({ shapeLayerUUID }: Geography) {
    const endpoint = '/vehicles/utilization_by_geo_by_operator'
    const region = appUIStore.metro.regionId
    const geographyName = layerStore.getLayer(shapeLayerUUID)?.layerName
    const title = i18n.t(
      'downloadReportsModal.utilizationByGeographyByOperatorTitle',
      '{{region}}_utilization_by_operator_by_geo_{{geographyName}}',
      { region, geographyName: lowerCase(geographyName) }
    )

    const payload = {
      start_date: this.dateRange.start!.toISODate(),
      end_date: this.dateRange.end!.toISODate(),
      shape_layer_uuid: shapeLayerUUID,
    }

    this.doRequestAndDownload(endpoint, payload, title)
  }
}

const downloadReportsModalStore = new DownloadReportsModalStore()

export const DownloadReportsModal = observer(({ onClose }: { onClose: () => void }) => {
  const [query, setQuery] = useState<{
    reportType: string | undefined
    shapeLayerUUID: string
    aggregation: string
  }>({
    reportType: undefined,
    shapeLayerUUID: '',
    aggregation: 'mean',
  })
  const [error, setError] = useState<string>()
  const [enableGeographyDropdown, setEnableGeographyDropdown] = useState(false)
  const [enableAggregationDropdown, setEnableAggregationDropdown] = useState(false)

  const { t } = useTranslation()

  useEffect(() => {
    setEnableGeographyDropdown(
      includes(['vehicles_by_day_by_geography', 'trips_top_od'], query.reportType)
    )

    setEnableAggregationDropdown(query.reportType === 'vehicles_by_day_by_geography')

    if (!query.shapeLayerUUID) {
      setQuery(curr => ({
        ...curr,
        shapeLayerUUID: layerStore.firstLayerUUID,
      }))
    }
  }, [query])

  downloadReportsModalStore.initDates()

  const _reportOptions = [
    {
      value: 'vehicles_by_day_by_geography',
      text: t('downloadReportsModal.vehiclesByDayByGeography', 'Vehicles By Day By Geography'),
    },
    {
      value: 'vehicles_by_hour',
      text: t('downloadReportsModal.vehiclesByHour', 'Vehicles by Hour by State'),
    },
    {
      value: 'trips_top_od',
      text: t('downloadReportsModal.tripsTopOd', 'Top 100 Trip Origin and Destination Pairs'),
    },
    {
      value: 'utilization_by_operator_by_geo',
      text: t(
        'downloadReportsModal.utilizationByOperatorByGeo',
        'Utilization by Operator by Geography'
      ),
    },
    {
      value: 'baltimore_equity_distribution',
      text: t('downloadReportsModal.baltimoreEquityDistribution', 'Equity Zone Distribution'),
      regionIds: ['baltimore'],
      orgNameAllowlist: ['Baltimore', 'Populus'],
    },
    {
      value: 'baltimore_planning_distribution',
      text: t(
        'downloadReportsModal.baltimorePlanningDistribution',
        'Planning District Distribution'
      ),
      regionIds: ['baltimore'],
      orgNameAllowlist: ['Baltimore', 'Populus'],
    },
    {
      value: 'calgary_trips_by_origin_neighborhood',
      text: t('downloadReportsModal.calgaryTripsByOriginNeighborhood'),
      regionIds: ['calgary'],
      orgNameWhitelist: ['Calgary', 'Populus'],
    },
    {
      value: 'calgary_trips_by_destination_neighborhood',
      text: t(
        'downloadReportsModal.calgaryTripsByDestinationNeighborhood',
        'Trips by Destination Neighbourhood'
      ),
      regionIds: ['calgary'],
      orgNameAllowlist: ['Calgary', 'Populus'],
    },
    {
      value: 'calgary_deployments_by_neighborhood',
      text: t(
        'downloadReportsModal.calgaryDeploymentsByNeighborhood',
        'Vehicle Counts by Neighbourhood'
      ),
      regionIds: ['calgary'],
      orgNameAllowlist: ['Calgary', 'Populus'],
    },
    {
      value: 'calgary_utilization',
      text: t('downloadReportsModal.calgaryUtilization', 'Daily Trips Summary'),
      regionIds: ['calgary'],
      orgNameAllowlist: ['Calgary', 'Populus'],
    },
  ]

  const reportOptions = chain(_reportOptions)
    .filter(opt =>
      opt.orgNameAllowlist && authStore.organizationName
        ? opt.orgNameAllowlist.includes(authStore.organizationName)
        : true
    )
    .filter(opt => (opt.regionIds ? opt.regionIds.includes(appUIStore.metro.regionId) : true))
    .map(opt => omit(opt, 'orgNameAllowlist'))
    .value()

  const handleSetQuery = (
    name: 'reportType' | 'shapeLayerUUID' | 'aggregation',
    value: string | undefined
  ) => {
    setQuery(curr => ({
      ...curr,
      [name]: value,
    }))
  }

  const {
    data: { regionId },
  } = useCurrentRegion()

  const downloadVehiclesByHour = async (outputFormat: keyof typeof fileFormats) => {
    const data = await internalApi.vehicles.vehiclesByHour({
      regionId,
      start: downloadReportsModalStore.dateRange.start!.toISODate()!,
      end: downloadReportsModalStore.dateRange.end!.toISODate()!,
      fields: ['vehicle_type', 'operator'],
      accept: fileFormats[outputFormat],
    })

    const filename = t('downloadReportsModal.vehiclesByHourTitle', '{{region}}_vehicles_by_hour', {
      region: regionId,
    })
    saveFile(data, `${filename}.${outputFormat}`, outputFormat)
  }

  const onSubmit = () => {
    setError(undefined)
    if (!query.reportType)
      setError(t('downloadReportsModal.errorSelectType', 'Please select a Report Type'))
    else {
      if (query.reportType === 'vehicles_by_hour') {
        downloadVehiclesByHour('csv')
      } else {
        downloadReportsModalStore.download(query.reportType, toJS(query))
      }
    }
    if (downloadReportsModalStore.error)
      setError(t('downloadReportsModal.errorSelection', 'Please try another selection'))
  }

  const geographySelection = (
    <Form.Field width={8} disabled={downloadReportsModalStore.loading}>
      <label htmlFor="geography">{t('common.geographyTitle', 'Geography')}</label>
      <Dropdown
        selection
        onChange={(event, { value }) =>
          handleSetQuery('shapeLayerUUID', value as string | undefined)
        }
        value={query.shapeLayerUUID}
        options={layerStore.availableVehicleCountOptions}
      />
    </Form.Field>
  )

  const aggregationDropdown = (
    <Form.Field width={8} disabled={downloadReportsModalStore.loading}>
      <label htmlFor="geography">{t('common.aggregationTitle', 'Aggregation')}</label>
      <Dropdown
        selection
        onChange={(event, { value }) => handleSetQuery('aggregation', value as string | undefined)}
        value={query.aggregation}
        options={[
          {
            value: 'mean',
            text: t('downloadReportsModal.aggregatioOptionAverage', 'Average'),
          },
          {
            value: 'max',
            text: t('downloadReportsModal.aggregatioOptionMax', 'Max'),
          },
        ]}
      />
    </Form.Field>
  )

  const timePeriodFilters = (
    <Form.Field width={8} disabled={downloadReportsModalStore.loading}>
      <label>{t('downloadReportsModal.dates', 'Dates')}:</label>
      <DatePicker
        range={{
          from: downloadReportsModalStore.dateRange.start?.toJSDate(),
          to: downloadReportsModalStore.dateRange.end?.toJSDate(),
        }}
        requireDates
        setRange={o => {
          downloadReportsModalStore.setDateRange({
            start: DateTime.fromJSDate(o.from!),
            end: DateTime.fromJSDate(o.to!),
          })
        }}
        dropdownOptions={['custom', 'singleDay', 'thisMonth', 'lastMonth']}
      />
    </Form.Field>
  )

  const form = (
    <Form>
      <Form.Group>
        <Form.Field width={8} disabled={downloadReportsModalStore.loading}>
          <label>
            <Trans i18nkey="downloadReportsModal.titleReports">Reports</Trans>
          </label>
          <Dropdown
            selection
            placeholder={t('downloadReportsModal.selectReport', 'Select a Report')}
            value={query.reportType}
            options={reportOptions.map(o => pick(o, ['value', 'text']))}
            onChange={(event, { value }) =>
              handleSetQuery('reportType', value as string | undefined)
            }
          />
        </Form.Field>
        {timePeriodFilters}
      </Form.Group>
      {enableGeographyDropdown && geographySelection}
      {enableAggregationDropdown && aggregationDropdown}

      <Form.Group>
        <Form.Field width={4}>
          <Form.Button
            loading={downloadReportsModalStore.loading}
            color="teal"
            content={t('common.Download', 'Download')}
            onClick={onSubmit}
          />
        </Form.Field>
      </Form.Group>
    </Form>
  )

  return (
    <Modal
      open={true}
      onClose={onClose}
      onOpen={() => {
        handleSetQuery('shapeLayerUUID', layerStore.availableVehicleCountOptions[0].value)
      }}
    >
      <Modal.Content>
        <h3>
          <Trans i18nkey="downloadReportsModal.buttonDownloadReports">Download Reports</Trans>
        </h3>
        {form}
        {error && (
          <Message negative>
            <Message.Header>
              <Trans i18nkey="downloadReportsModal.downloadError">Error downloading data</Trans>
            </Message.Header>

            <p>{error}</p>
          </Message>
        )}
      </Modal.Content>
    </Modal>
  )
})
